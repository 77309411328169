import React, { useRef, useEffect } from 'react';
import Plot from 'react-plotly.js';

function EtpHistoryGraph(props) {

    const dataEtp = props.data;
    const arrayOfDates = dataEtp.date;
    const arrayOfEtp = dataEtp.etpHistory;
    const arrayOfMissing = dataEtp.irrigation;
    const arrayOfCumMissing = dataEtp.cumIrrigation;

    const graphType = props.graphType;

    /*
    const arrayOfDates = ['1900-01-01', '1900-01-02', '1900-01-03', '1900-01-04', '1900-01-05']
    const arrayOfEtp = [1, 2, 3, 2, 0]
    const arrayOfMissing = [1, 5, 5, 5, 0]
    const arrayOfCumMissing = [1, 3, 6, 8, 8]
    */

    let arrayx;
    let titleGraph;
    switch (graphType) {
        case '1':
            arrayx = arrayOfEtp;
            titleGraph = 'Evapotranspiration (mm)';
            break;
        case '2':
            arrayx = arrayOfMissing;
            titleGraph = 'Missing Water (mm)';
            break;
        case '3':
            arrayx = arrayOfCumMissing;
            titleGraph = 'Cumulative Missing Water (mm)';
            break;
        default:
            arrayx = arrayOfEtp;
            titleGraph = 'Evapotranspiration (mm)';
            break;
    }

    const plotContainer = useRef(null);

    useEffect(() => {
        function updateSize() {
            const { current } = plotContainer;

            if (current) {
                Plot.update(current, {
                    width: current.offsetWidth,
                    height: current.offsetHeight
                });
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);



    const data = [
        {
            x: arrayx,
            y: arrayOfDates,
            type: 'bar',
            orientation: 'h',
            name: 'ETP'
        }
    ];

    const layout = {
        margin: {
            t: 30,
            r: 10,
            l: 40,
            b: 100
        },
        font: {
            family: 'Times New Roman',
            size: 12,
            color: '#ffffff'
        },
        xaxis: {
            title: titleGraph,
            linecolor: '#ffffff',
            zerolinecolor: '#ffffff',
            fixedrange: true //unactive horizontal zoom
        },
        yaxis: {
            linecolor: '#ffffff',
            zerolinecolor: '#ffffff',
            fixedrange: true // unactive vertical zoom
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        hovermode: false,              // unactive hover for the graph
        dragmode: false                // unactive dragging for the graph
    };

    return (

        <Plot
            data={data}
            layout={layout}
            config={{
                responsive: true,
                displayModeBar: false
            }}
            style={{
                width: '100%',
                height: '100%'
            }}
        />

    );
}

export default EtpHistoryGraph;