import React from 'react';

const ErrorPage = () => {
  return (
    <div className='etp-bg'>
      <div className='container'>
        <h2>Error : this page is under construction !</h2>
        {/*<img src={waterDrop} alt="error page" />*/}
      </div>
    </div>
  )
}

export default ErrorPage;