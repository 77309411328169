import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const bottomBoxClass = isLoaded ? "bottomBox show" : "bottomBox";

  return (
    <main className='welcomePage'>
      <div className='topBox'>
        <div className='leftBox'>
          <Link className='btn-welcome' to="/signup">Signup</Link>
        </div>
        <div className='rightBox'>
          <Link className='btn-welcome' to="/login">Login</Link>
        </div>
      </div>
      <div className='middleBox'>
        <Link className='btn-welcome' to="/case">Case in point</Link>
      </div>
      <div className={bottomBoxClass}>
        <h2>Optimize</h2>
        <h2>Water</h2>
        <h2>Usage</h2>
        <h2>for</h2>
        <h2>plant</h2>
        <h2>care</h2>
      </div>
    </main>
  )
}

export default Landing;