import React from 'react';

const Header = () => {
  return (
    <header>
      <div className='banner-container'>
        <h1><a href='/'>Smart Water</a></h1>
      </div>
    </header>
  )
}

export default Header;