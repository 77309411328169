//React components
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//CSS
import '../../App.css';

//Application components
import Header from '../Header';
import Landing from '../Landing';
import Footer from '../Footer';
import Case from '../Case';
import ErrorPage from '../ErrorPage';

function App() {

    return (
        <div>

            <Router>

                <Header />

                <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route path="/case" element={<Case />} />
                    {/*<Route path="/login" element={<Login />} />*/}
                    {/*<Route path="/signup" element={<Signup />} />*/}
                    {/*<Route path="/forgetpassword" element={<ForgetPassword />} />*/}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>

                <Footer />

            </Router>
        </div>
    );
}

export default App;
