import React, { useState, useEffect } from "react";
import EtpHistoryGraph from "./EtpHistoryGraph";

function Case() {
  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;
  const url = host + "/api/postCaseComputeETP";
  const [data, setData] = useState([{}]);

  const [selectedOptionCycle, setSelectedOptionCycle] = useState(1);
  const [selectedOptionGraph, setSelectedOptionGraph] = useState(1);
  //const [selectedOptionIrrTime, setSelectedOptionIrrTime] = useState(0);

  const waterVolume = 0;

  const handlePlantCycleChange = (event) => {
    setSelectedOptionCycle(event.target.value);
  };

  const handleGraphChange = (event) => {
    setSelectedOptionGraph(event.target.value);
  };

  const handleIrrigationTimeChange = (event) => {
    //setSelectedOptionIrrTime(event.target.value);
  };

  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cyclePlante: selectedOptionCycle }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error(error));
  }, [selectedOptionCycle, selectedOptionGraph, url]);

  return (
    <div className="etpBox">
      <div className="etpHistoryBox">
        <h3>Water History</h3>
        <fieldset className="selectBox">
          <legend>Plant cycle selection menu</legend>
          <select autoComplete="on" onChange={handlePlantCycleChange} required>
            <option value="1">Planting to Recovery</option>
            <option value="2">Recovery to 3rd bouquet flowering</option>
            <option value="3">3rd bouquet flowering to Mid-harvest</option>
            <option value="4">Mid-harvest to End of culture</option>
          </select>
        </fieldset>
        <fieldset className="selectBox">
          <legend>Graph selection</legend>
          <select autoComplete="on" onChange={handleGraphChange} required>
            <option value="1">Evapotranspiration</option>
            <option value="2">Missing Water</option>
            <option value="3">Cumulative Missing Water</option>
          </select>
        </fieldset>
        <div className="etpHistoryGraphBox">
          <EtpHistoryGraph data={data} graphType={selectedOptionGraph} />
        </div>
      </div>
      <div className="irrigationBox">
        <h3>Irrigation</h3>
        <fieldset className="selectBox">
          <legend>Time since the last irrigation</legend>
          <select
            autoComplete="on"
            onChange={handleIrrigationTimeChange}
            required
          >
            <option value="1">1 day</option>
            <option value="2">2 days</option>
            <option value="3">3 days</option>
            <option value="4">4 days</option>
            <option value="5">5 days</option>
          </select>
        </fieldset>
        <fieldset className="selectBox">
          <legend>Pluviometry since the last irrigation</legend>
          <input></input>
          <span>mm</span>
        </fieldset>
        <fieldset className="selectBox">
          <legend>Recommended water volume</legend>
          <p>{`Volume required for irrigation: ${waterVolume} mm`}</p>
        </fieldset>
      </div>
    </div>
  );
}

export default Case;
