import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className='footer-container'>
        <p>© 2023 - ttsdev - Tous droits réservés</p>
      </div>
    </footer>
  )
}

export default Footer